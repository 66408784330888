import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CheckboxLabel, CheckBoxContainer, CheckBoxInput } from './styles'
import { Text } from "@/components/blocks/Text"
import { Field } from 'formik'


export function SingleCheckField(props) {
    const [checked, setChecked] = useState(props.checked)
    const { label, required } = props;

    const flip = (e) => {
        e.preventDefault();
        setChecked(!checked)
    }

    return (
        <Field
            {...props}
            render={({ field, form: { isSubmitting } }) => (
                <CheckBoxContainer>
                    <CheckBoxInput type="checkbox" {...field} checked={props.checked} />
                    <CheckboxLabel>
                        {label} {required && <Text variant="warning">*</Text>}
                    </CheckboxLabel>
                </CheckBoxContainer>

            )} />
    )
}

SingleCheckField.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool
}

SingleCheckField.defaults = {
    label: "Placeholder",
    required: false
}
