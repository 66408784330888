/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import md5 from 'md5';
import {
  Row, Grid, Box, ContentText
} from 'elemental-ui';

import { TextField } from "@/components/blocks/Input"
import { Text } from '@/components/blocks/Text';
import { Formik } from 'formik';
import SaveFormAction from '@/components/blocks/SaveFormAction';
import ErrorMessage from '@/components/blocks/ErrorMessage';
import { MAX_WIDTH } from '@/constants';
import { dragableItemsMapper } from '@/helpers/mappers/dragableItemsMapper';
import ModalWindow from '@/components/blocks/AdminPanelEditFieldModalWindow';
import arrayMove from 'array-move';
import SortableList from '@/components/blocks/SortableList';
import FormActionsCancel from '@/components/blocks/FormActionsCancel';

class AdminPanelDynamicForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fields: [],
      focusElement: null,
      selectedElelement: false,
    };
  }

  componentDidMount() {
    const { form } = this.props;
    const { formConfiguration } = form;
    const { fields } = formConfiguration;
    this.setState({
      fields,
    });
  }

  onDragOver = (ev) => {
    ev.preventDefault();
  };

  onDropForm = (ev) => {
    const name = ev.dataTransfer.getData('name');
    const { fields } = this.state;
    const addElemet = [].concat(fields);
    addElemet.push(dragableItemsMapper(name));
    this.setState({
      fields: addElemet,
    });
  };

  deleteElement = (id) => {
    const { fields } = this.state;
    const filterElemetntsArray = fields.filter(
      element => element.id !== id,
    );
    this.setState({
      fields: filterElemetntsArray,
      selectedElelement: false,
    });
  };

  selectElement = (id) => {
    const { editFieldOpen } = this.props;
    editFieldOpen();
    this.setState({
      focusElement: id,
      selectedElelement: true,
    });
  };

  saveElement = (values, selectElementIndex) => {
    const { fields } = this.state;
    const addElemet = [].concat(fields);
    if (values.items) {
      const strItemToArr = values.itemsStr.split('\n');
      const itemNameOrLAbel = values.component === 'Select' ? 'name' : 'label';
      const items = strItemToArr.map(
        item => (item = { [itemNameOrLAbel]: item, value: item.replace('.', '_') }),
      );
      values.items = items;
    }
    values.name = values.component === 'FileUploader' ? 'fileUpload' : md5(values.label);
    addElemet[selectElementIndex] = values;
    this.setState({
      fields: addElemet,
    });
  };

  closeProperties = () => {
    this.setState({
      focusElement: null,
      selectedElelement: false,
    });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ fields }) => ({
      fields: arrayMove(fields, oldIndex, newIndex),
    }));
  };

  renderForm = ({
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    setFieldValue,
  }) => {
    const { fields } = this.state;
    const { closeProperties, selectElement } = this;
    const { saveFormikValues } = this.props;
    saveFormikValues({
      errors,
      touched,
      values,
      handleChange,
      handleBlur,
      handleSubmit,
      isValid,
      setFieldValue,
      selectElement,
      closeProperties,
    });
    return (
      <form
        onSubmit={handleSubmit}
        onDragOver={e => this.onDragOver(e)}
        onDrop={e => this.onDropForm(e)}
        className="admin-panel__form"
      >
        <Box
          style={{
            borderBottom: 'solid 2px #e8e8eb',
            marginBottom: '30px',
          }}
        >
          <div>
            <ContentText>
              Form Title
              {" "}
              <Text variant="warning">*</Text>
            </ContentText>
            <TextField
              name="title"
              label="Form Title"
              onChange={handleChange}
              marginTop="10px"
              marginBottom="15px"
              error={!values.title}
              errorMessage="Enter a form title"
              value={values.title}
            />
          </div>


          <div>
            <ContentText>
              Interface name
              {" "}
              <Text variant="warning">*</Text>
            </ContentText>
            <TextField
              name="contentInterfaceID"
              label="Interface name"
              onChange={handleChange}
              marginTop="10px"
              marginBottom="30px"
              error={!values.contentInterfaceID}
              errorMessage="Enter a interface name"
              value={values.contentInterfaceID}
            />
          </div>

        </Box>
        <SortableList items={fields} onSortEnd={this.onSortEnd} />
        <Row>
          <SaveFormAction />
          <FormActionsCancel />
          <ErrorMessage />
        </Row>
      </form>
    );
  };

  handleFormSubmit = (values) => {
    const { fields } = this.state;
    const {
      formIsUpdated, updateFormRequest, form, notificationOpen,
      createFormRequest, id,
    } = this.props;
    const { document_id } = form;
    const formConfiguration = {
      fields,
      title: values.title,
      contentInterfaceID: values.contentInterfaceID,
    };
    if (formIsUpdated) updateFormRequest(document_id !== '' ? document_id : id, formConfiguration);
    else createFormRequest(formConfiguration, `f${(+new Date()).toString(16)}`);

    notificationOpen();
  }

  render() {
    const {
      focusElement, fields, selectedElelement,
    } = this.state;

    const { form } = this.props;


    const { deleteElement } = this;
    const selectElementProps = fields.filter(
      element => element.id === focusElement,
    )[0];
    const selectElementIndex = selectedElelement
      ? fields.indexOf(selectElementProps)
      : 0;
    const { saveElement } = this;
    return (
      <div>
        {selectedElelement ? (
          <ModalWindow
            deleteElement={deleteElement}
            selectElementIndex={selectElementIndex}
            saveElement={saveElement}
            element={selectElementProps}
          />
        ) : null}
        <div className="container">
          <Grid style={MAX_WIDTH}>
            <Formik
              initialValues={{
                contentInterfaceID: form !== null ? form.formConfiguration.contentInterfaceID : '',
                title: form !== null ? form.formConfiguration.title : '',
              }}
              onSubmit={this.handleFormSubmit}
            >
              {this.renderForm}
            </Formik>
          </Grid>
        </div>
      </div>
    );
  }
}

AdminPanelDynamicForm.propTypes = {
  editFieldOpen: PropTypes.func.isRequired,
  saveFormikValues: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  formIsUpdated: PropTypes.bool.isRequired,
  updateFormRequest: PropTypes.func.isRequired,
  notificationOpen: PropTypes.func.isRequired,
  createFormRequest: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};
AdminPanelDynamicForm.defaultProps = {};

export default AdminPanelDynamicForm;
